<template>
  <div class="login">
    <div class="content-box">
      <router-link class="logo" to="/"
        ><img src="~img/account/logo.png" alt=""
      /></router-link>
      <div class="formWrap">
        <div class="item border-item clearfix">
          <img class="fl" src="~img/account/icon_user@2x.png" alt="" />
          <input
            v-model="loginValue.account"
            class="fl"
            type="text"
            placeholder="請輸入手機號"
          />
        </div>
        <div class="item border-item clearfix">
          <img class="fl" src="~img/account/icon_pass@2x.png" alt="" />
          <input
            class="fl code-input"
            v-model="loginValue.password"
            type="password"
            placeholder="請輸入密碼"
          />
        </div>
        <div class="item noneitem">
          <button @click="submitLogin" class="submit-btn">登錄</button>
        </div>
        <div class="item clearfix">
          <div class="fl clearfix">
            <div class="check fl" @click="chooseCheck">
              <img v-if="checkValue" src="~img/account/option2@2x.png" alt="" />
              <img v-else src="~img/account/option3@2x.png" alt="" />
            </div>
            <p class="fl">記住我</p>
          </div>
          <router-link to="/forget" class="fr forget">
            忘記密碼？
          </router-link>
        </div>
      </div>
    </div>
    <loginelse></loginelse>
  </div>
</template>

<script>
import {
  isMobile,
  encryptStr,
  isNull,
  isPwd,
  setCookie,
  getCookie,
  delCookie
} from "utils/core/kernel";
export default {
  data() {
    return {
      checkValue: true,
      loginValue: {
        account: "",
        password: ""
      }
    };
  },
  created() {
    this.$nextTick(() => {
      if (getCookie("psw")) {
        this.loginValue = {
          account: getCookie("account"),
          password: getCookie("psw")
        };
      }
    });
  },
  methods: {
    chooseCheck() {
      this.checkValue = !this.checkValue;
    },
    // 登錄
    submitLogin() {
      if (isNull(this.loginValue.account) || isNull(this.loginValue.password)) {
        this.Toast({
          message: "手機號和密碼不能爲空~",
          duration: 2000,
          position: "top"
        });
      } else if (!isMobile(this.loginValue.account)) {
        this.Toast({
          message: "請輸入正確的手機號~",
          duration: 2000,
          position: "top"
        });
      } else if (!isPwd(this.loginValue.password)) {
        this.Toast({
          message: "密碼不符合規範~",
          duration: 2000,
          position: "top"
        });
      } else {
        let data = {
          account: encryptStr(this.loginValue.account),
          password: encryptStr(this.loginValue.password)
        };
        this.$store
          .dispatch("Login", data)
          .then(res => {
            if (res.success) {
              if (this.checkValue) {
                setCookie("account", this.loginValue.account, 7);
                setCookie("psw", this.loginValue.password, 7);
              } else {
                delCookie("account");
                delCookie("psw");
              }
              if (this.$route.query.redirect) {
                this.$router.replace(this.$route.query.redirect);
              } else {
                this.$router.replace("/");
              }
            } else {
              if (res.code == 20001) {
                this.$router.replace("/");
              }
            }
          })
          .catch(() => {});
      }
    }
  }
};
</script>
<style lang="stylus" scoped></style>
